import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/en"
import IndexPageTemplate from "../templates/index-page"

const IndexPage = props => {
  const { title } = props.data.markdownRemark.frontmatter.hero

  return (
    <Layout location={props.location} title={title}>
      <IndexPageTemplate {...props} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageEN {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/index/index.en.md/" }
    ) {
      frontmatter {
        ...HeroSection
        ...ClientsSection
        ...AboutSection
        ...FounderSection
        ...FeaturesSection
        ...BlogSection
        ...SubscribeSection
        ...ReviewsSection
      }
    }

    blogPosts: allBlogPostEn(limit: 3) {
      nodes {
        published_at
        id
        title
        content
        image {
          title
          url
        }
      }
    }
  }
`
